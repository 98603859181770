
window.addEventListener('load', ()=> {
    const select1 = document.querySelector('#select');
    const opciones = document.querySelector('#opciones');
    const contenidoSelect = document.querySelector('#select .contenido-select');
    const hiddenInput = document.querySelector('#inputSelect');
    
    document.querySelectorAll('#opciones > .opcion').forEach((opcion) => {
        opcion.addEventListener('click', (e) => {
            e.preventDefault();
            contenidoSelect.innerHTML = e.currentTarget.innerHTML;
            select1.classList.toggle('active');
            opciones.classList.toggle('active');
            hiddenInput.value = e.currentTarget.querySelector('.titulo').innerText;
        });
    });
    
    if (select1) {
        select1.addEventListener('click', () => {
            select1.classList.toggle('active');
            opciones.classList.toggle('active');
        });
    }

})
