/* function scrollFunction() {
    if (window.innerWidth > 992) {
        
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.getElementById("menu").style.height = "90px";
            
        } else {
            document.getElementById("menu").style.height = "100px";
        }
    }
}

window.addEventListener('scroll', () => {
    scrollFunction()
});

window.addEventListener('resize', () => {
    scrollFunction()
})
 */
